import React from 'react';
import {graphql} from 'gatsby';
import {
    PageContainerDPC,
    CardContainerDPC,
    CardElencoFotoVideoDPC,
    FormFiltriDPC,
    PrintButtonDPC,
    CardHorizontalContainerDPC, CardHorizontalDPC
} from '@dpc-fe/shared';
import DpcPaginator from "@dpc-fe/shared/components/dpc-paginator";
import {getSlug, prop} from "../../functions/functions";
import moment from "moment";
import {buildMenu} from "@dpc-fe/shared/js/shared-functions";

class ElencoFotoVideo extends React.Component {

    constructor(props){
        super(props);
        this.setPage = this.setPage.bind(this);
        this.filtra = this.filtra.bind(this);
        this.itemsPerPage = 9;
        this.fullData = [].concat(this.props.data.allNodeGalleriaFoto.edges,this.props.data.allNodeVideo.edges,this.props.data.allNodeGalleriaFlickr.edges).sort((a,b) => {
            return moment(a.node.field_data).isBefore(moment(b.node.field_data), "day") ? 1 : -1;
        }).map(item => {
          if(item.node.field_categoria_primaria === 'galleria_foto'){
            return {
              title: item.node.field_titolo_esteso,
              slug: getSlug('/' + this.props.pageContext.lang + item.node.fields.slug, 'Portale', item.node.relationships.field_sottodominio.name),
              categoria:'Foto',
              image:item.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL,
              alt: item.node.relationships?.field_immagine_dettaglio?.field_alt,
              didascalia: item.node.relationships?.field_immagine_dettaglio?.field_didascalia,
              data: item.node.field_data,
              ambiti: Array.isArray(item.node.relationships?.field_ambito) ? item.node.relationships?.field_ambito :  [],
              territorio: item.node.relationships?.field_territorio,
              rischi: Array.isArray(item.node.relationships?.field_rischio) ? item.node.relationships?.field_rischio :  [],
            }
          }else if (item.node.field_categoria_primaria === 'video') {
            return {
              title: item.node.field_titolo_esteso,
              slug: getSlug('/' + this.props.pageContext.lang + item.node.fields.slug, 'Portale', item.node.relationships.field_sottodominio.name),
              categoria: 'Video',
              image: item.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL,
              alt: item.node.relationships?.field_immagine_dettaglio?.field_alt,
              didascalia: item.node.relationships?.field_immagine_dettaglio?.field_didascalia,
              data: item.node.field_data,
              ambiti: Array.isArray(item.node.relationships?.field_ambito) ? item.node.relationships?.field_ambito :  [],
              territorio: item.node.relationships?.field_territorio,
              rischi: Array.isArray(item.node.relationships?.field_rischio) ? item.node.relationships?.field_rischio :  [],
            }
          } else if (item.node.field_categoria_primaria === 'galleria_flickr') {
            return {
              title: item.node.field_titolo_esteso,
              slug: getSlug('/' + this.props.pageContext.lang + item.node.fields.slug, 'Portale', item.node.relationships.field_sottodominio.name),
              categoria: 'Foto',
              image: item.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL,
              alt: item.node.relationships?.field_immagine_dettaglio?.field_alt,
              didascalia: item.node.relationships?.field_immagine_dettaglio?.field_didascalia,
              data: item.node.field_data,
              ambiti: Array.isArray(item.node.relationships?.field_ambito) ? item.node.relationships.field_ambito :  [],
              territorio: item.node.relationships?.field_territorio,
              rischi: Array.isArray(item.node.relationships?.field_rischio) ? item.node.relationships.field_rischio :  [],
            }
          }
        }).filter(Boolean);
        this.data = [...this.fullData];
        if(typeof window !== 'undefined' && sessionStorage.getItem('fotoVideoItPage')) {
            let intervalloInizio = (this.itemsPerPage * Number(sessionStorage.getItem('fotoVideoItPage'))) - this.itemsPerPage;
            let intervalloFine = Math.min((intervalloInizio + this.itemsPerPage), this.data.length);
            this.state = {intervalloInizio, intervalloFine, currentPage: Number(sessionStorage.getItem('fotoVideoItPage'))};
        }else {
            this.state = {intervalloInizio: 0, intervalloFine: Math.min(this.itemsPerPage, this.data.length), currentPage: 1};
        }

    }

    setPage(page){
        let intervalloInizio = (this.itemsPerPage * page) - this.itemsPerPage;
        let intervalloFine = Math.min((intervalloInizio + this.itemsPerPage), this.data.length);
        this.setState({
            intervalloInizio,
            intervalloFine,
            currentPage: page
        });
        if(typeof window !== 'undefined'){
            sessionStorage.setItem('fotoVideoItPage', page);
        }

    }

    filtra(filters){
        this.data = this.fullData.filter(item => {
            if(filters.searchContent === "Solo foto" && item.categoria !== "foto")
                return false;
            if(filters.searchContent === "Solo video" && item.categoria !== "video")
                return false;
            if(filters.searchDateInit && moment(filters.searchDateInit, "DD/MM/YYYY").isAfter(moment(item.data), "day"))
                return false;
            if(filters.searchDateFine && moment(filters.searchDateFine, "DD/MM/YYYY").isBefore(moment(item.data), "day"))
                return false;
            if(filters.searchAmbn.length)
                if(!item.ambiti.some(i => filters.searchAmbn.includes('' + i.drupal_internal__tid)))
                    return false;
            if(filters.searchTerr.length)
                if(!item.territorio || !filters.searchTerr.includes('' + item.territorio.drupal_internal__tid))
                    return false;
            if(filters.searchRisk.length)
                if(!item.rischi.some(i => filters.searchRisk.includes('' + i.drupal_internal__tid)))
                    return false;
            if(filters.searchQuery && filters.searchQuery.trim().length)
                if(!item.title || item.title.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) < 0)
                    return false;
            //A questo punto ha superato tutti i filtri e verrà compreso nei risultati
            return true;
        });
        this.setPage(1);
    }

    render(){
        return (
            <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                              menuItems={this.props.pageContext.menu}
                              title={this.props.data.node.title}
                              slugTraduzione={this.props.data.node.relationships.field_riferimento_traduzione ? this.props.data.node.relationships.field_riferimento_traduzione.fields.slug : ''}
                              env={process.env.ENV}
                              lang={this.props.pageContext.lang}
                              minisiti={prop}
                              immagineShare={this.props.data.node.relationships?.field_immagine_dettaglio ? process.env.PORTALE_URL + this.props.data.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL: process.env.PORTALE_URL + this.props.pageContext.socialImg?.relationships?.image?.localFile?.publicURL}
                              abstractShare={this.props.data.node.body?.processed ? this.props.data.node.body.processed.replace(/<\/?[^>]+(>|$)/g, "") : ''}
                              slug={process.env.PORTALE_URL + '/' + this.props.pageContext.lang + this.props.data.node.fields.slug}
                              menuFooterItems={this.props.pageContext.menuFooter}
            >
                <section className="container article-card-simple sezione-foto-e-video">
                    <h1>{this.props.data.node.field_titolo_esteso}</h1>

                    {(this.props.data?.node?.body || this.props.data?.node?.relationships?.field_immagine_anteprima) &&
                    <section className="primo-piano">
                        <div className="px-custom">
                            <div className="row">
                                <div className="col-12 col-xl-6">
                                    <img
                                        src={this.props.data?.node?.relationships?.field_immagine_anteprima?.relationships?.image?.localFile?.publicURL}
                                        className="img-fluid w-100 my-3"
                                        alt={this.props.data?.node?.relationships?.field_immagine_anteprima?.field_alt}/>
                                </div>
                                <div className="col-12 col-xl-6">
                                    <div className="my-3"
                                         dangerouslySetInnerHTML={{__html: this.props.data.node.body ? this.props.data.node.body.processed : ''}}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    }

                    <FormFiltriDPC lang={'it'}
                        filtra={this.filtra}
                        ambiti={buildMenu(this.props.data.ambiti.edges, this.props.pageContext.lang, process.env.ENV)}
                        rischi={buildMenu(this.props.data.rischi.edges, this.props.pageContext.lang, process.env.ENV)}
                        territori={buildMenu(this.props.data.territori.edges, this.props.pageContext.lang, process.env.ENV)}
                        contenutiMultimediali={true}
                    />

                    <div className="px-custom">
                        <h2 className="sr-only">Risultati ricerca contenuti multimediali</h2>
                        <div className={"row"}>
                            {this.data
                                .slice(this.state.intervalloInizio, this.state.intervalloFine)
                                .map(item => {
                                    let key = Math.random();
                                    return (
                                        <div className="col-12 col-md-4">
                                            <CardElencoFotoVideoDPC element={item} key={key}/>
                                        </div>
                                    )
                                })}
                        </div>
                        {typeof window !== 'undefined' &&
                        <DpcPaginator setPage={this.setPage} numItems={this.data.length}
                                      itemsPerPage={this.itemsPerPage} key={this.data.length} currentPage={this.state.currentPage}/>}
                        <PrintButtonDPC/>
                    </div>
                </section>
            </PageContainerDPC>
        );
    }

}

export default ElencoFotoVideo;

export const query = graphql`
  query($slug: String!) {
    node:nodeElencoFotoEVideo (fields: { slug: { eq: $slug } }) {
      title
      field_titolo_esteso
      drupal_internal__nid
      fields {
        slug
      }
      body {
        value
        processed
      }
      relationships {
        field_immagine_anteprima {
          field_alt
          field_didascalia
          relationships {
            image:field_immagine {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        field_riferimento_traduzione {
          fields {
            slug
          }
        }
      }
    },
    allNodeGalleriaFoto(filter: {field_codice_lingua: {eq: false}, relationships: {field_sottodominio: {name: {nin: ["Io Non Rischio", "IT Alert", "INR Attimo Decisivo"]}}}}) {
      edges {
        node {
          field_titolo_esteso
          field_categoria_primaria
          fields {
            slug
          }
          relationships {
            field_sottodominio {
              name
            }
            field_ambito {
              drupal_internal__tid
            }
            field_territorio {
              drupal_internal__tid
            }
            field_rischio {
              drupal_internal__tid
            }
            field_immagine_dettaglio {
                    field_alt
                    field_didascalia
                    relationships {
                      image:field_immagine{
              localFile {
			    publicURL
                childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid_noBase64
                            }
                          }
                        }
                      }
                    }
                  }
            field_immagine_gallery {
              field_alt
              field_didascalia
              relationships {
                image:field_immagine{
              localFile {
			    publicURL
                childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
              field_data
              field_categoria_primaria
            }
          }
          field_data

        }
      }
    },
    allNodeVideo(filter: {field_codice_lingua: {eq: false}, relationships: {field_sottodominio: {name: {nin: ["Io Non Rischio", "IT Alert", "INR Attimo Decisivo"]}}}}) {
        edges {
            node {
                field_categoria_primaria
                relationships {
                  field_sottodominio {
                    name
                  }
                  field_ambito {
                    drupal_internal__tid
                  }
                  field_territorio {
                    drupal_internal__tid
                  }
                  field_rischio {
                    drupal_internal__tid
                  }
                  field_immagine_dettaglio {
                    field_alt
                    field_didascalia
                    relationships {
                      image:field_immagine{
              localFile {
			    publicURL
                childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid_noBase64
                            }
                          }
                        }
                      }
                    }
                  }
                }
                field_titolo_esteso
                field_didascalia
                field_alt
                field_data
                field_video {
                  title
                  uri
                }
                fields {
                  slug
                }
                internal {
                  type
                }
            }
        }
    },
    allNodeGalleriaFlickr(filter: {field_codice_lingua: {eq: false}, relationships: {field_sottodominio: {name: {nin: ["Io Non Rischio", "IT Alert", "INR Attimo Decisivo"]}}}}) {
      edges {
        node {
          fields {
            slug
          }
          field_link_flickr
          field_categoria_primaria
          title
          field_titolo_esteso
          field_data
          relationships {
            field_immagine_dettaglio {
                    field_alt
                    field_didascalia
              relationships {
                image:field_immagine{
              localFile {
			    publicURL
                childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
            field_sottodominio {
              name
            }
            field_ambito {
              drupal_internal__tid
            }
            field_territorio {
              drupal_internal__tid
            }
            field_rischio {
              drupal_internal__tid
            }
          }
        }
      }
    },
    rischi: allTaxonomyTermRischio {
      edges {
        node {
          name
          relationships {
            parent {
              name
              drupal_internal__tid
            }
          }
          drupal_internal__tid
        }
      }
    },
    territori: allTaxonomyTermTerritorio {
      edges {
        node {
          name
          relationships {
            parent {
              name
              drupal_internal__tid
            }
          }
          drupal_internal__tid
        }
      }
    },
    ambiti: allTaxonomyTermAmbito {
      edges {
        node {
          name
          relationships {
            parent {
              name
              drupal_internal__tid
            }
          }
          drupal_internal__tid
        }
      }
    }
  }
`;
